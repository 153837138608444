import React, { useState, useEffect } from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import startOrJoinImg from '../../assets/onboardingPageThumbnail.webp';
import StartOrJoinOrganization from '../../components/Onboarding/StartOrJoinOrganization';
import OganizationName from '../../components/Onboarding/OganizationName';
import CompanySize from '../../components/Onboarding/CompanySize';
import WorkingPeople from '../../components/Onboarding/WorkingPeople';
import Solution from '../../components/Onboarding/Solution';
import HearAboutUs from '../../components/Onboarding/HearAboutUs';
import OrganizationDetails from '../../components/Onboarding/OrganizationDetails';
import InvitePeople from '../../components/Onboarding/InvitePeople';
import JoinOrganization from '../../components/Onboarding/JoinOrganization';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Onboarding() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);

  const renderStep = () => {
    switch (step) {
      case 0:
        return <StartOrJoinOrganization setStep={updateStep} />;
      case 1:
        return <OganizationName setStep={updateStep} />;
      case 2:
        return <CompanySize setStep={updateStep} />;
      case 3:
        return <WorkingPeople setStep={updateStep} />;
      case 4:
        return <Solution setStep={updateStep} />;
      case 5:
        return <HearAboutUs setStep={updateStep} />;
      case 6:
        return <OrganizationDetails setStep={updateStep} />;
      case 7:
        return <InvitePeople />;
      case -1:
        return <JoinOrganization setStep={updateStep} />;
      default:
        return <StartOrJoinOrganization setStep={updateStep} />;
    }
  };

  const stepImages = {
    0: startOrJoinImg,
    1: startOrJoinImg,
    2: startOrJoinImg,
    3: startOrJoinImg,
    4: startOrJoinImg,
    5: startOrJoinImg,
    6: startOrJoinImg,
    7: startOrJoinImg,
    '-1': startOrJoinImg,
  };

  const updateStep = (newStep) => {
    setStep(newStep);
    localStorage.setItem('onboardingStep', newStep.toString());

    if (newStep === 1) {
      navigate('?create=true', { replace: true });
    } else if (newStep === -1) {
      navigate('?join=true', { replace: true });
    } else if (newStep === 0) {
      navigate('', { replace: true });
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const createParam = searchParams.get('create');
    const joinParam = searchParams.get('join');
    const storedStep = parseInt(localStorage.getItem('onboardingStep'), 10);

    if (createParam === 'true') {
      if (storedStep) {
        setStep(storedStep);
      } else {
        setStep(1);
        localStorage.setItem('onboardingStep', '1');
      }
    } else if (joinParam === 'true') {
      if (storedStep) {
        setStep(storedStep);
      } else {
        setStep(-1);
        localStorage.setItem('onboardingStep', '-1');
      }
    } else if (storedStep) {
      setStep(storedStep);
    } else {
      setStep(0);
      localStorage.removeItem('onboardingStep');
    }
  }, [location]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column-reverse', md: 'row' },
        width: '100%',
        minHeight: '100vh',
      }}
    >
      <Box
        sx={{
          width: { xs: '100%', md: '50%' },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          textAlign: 'left',
          padding: { xs: '0', md: '0' },
        }}
      >
        {renderStep()}
      </Box>
      <Box
        sx={{
          width: { xs: '100%', md: '50%' },
          backgroundColor: 'rgba(235, 239, 255, 1)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'right',
          padding: { xs: '30px 0', md: '0' },
        }}
      >
        <img
          src={stepImages[step]}
          alt={`Step ${step} Illustration`}
          style={{
            maxWidth: '90%',
            height: 'auto',
            maxHeight: isSmallScreen ? '50%' : '80%',
          }}
        />
      </Box>
    </Box>
  );
}
