import React from 'react';
import { Avatar, Box, Tooltip } from '@mui/material';
import { CommonColors } from '../../utils/colors';

export default function ChatListCard({
  handleChatClick,
  profilePhotoLink,
  name,
  latestMessage,
  chat,
  selectedChat,
  index,
  unreadMessagesCount,
  senderId,
  currentuser,
}) {
  const bgColor = CommonColors.primaryBgColor;
  const activeChatBgColor = '#f4f6ff';
  return (
    <Box
      key={index}
      onClick={() => handleChatClick(chat)}
      sx={{
        cursor: 'pointer',
        padding: 0.5,
        backgroundColor: selectedChat === chat ? activeChatBgColor : '',
        border: selectedChat === chat ? '1px solid #ebebeb' : '',
        marginX: selectedChat === chat ? { sm: '12px' } : { sm: '12px' },
        borderRadius: selectedChat === chat ? '8px' : '',
        display: 'flex',
        alignItems: 'center',
        overflowX: 'hidden',
      }}
    >
      {profilePhotoLink ? (
        <img
          src={profilePhotoLink}
          alt="profile"
          style={{
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            marginRight: '10px',
          }}
        />
      ) : (
        <Avatar
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '30px',
            height: '30px',
            marginRight: '10px',
            backgroundColor: bgColor,
            color: '#fff',
            fontFamily: 'Poppins',
            fontSize: '19px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
            borderRadius: '4px',
            textAlign: 'center',
            textTransform: 'uppercase',
          }}
        >
          {name && name.charAt(0)}
        </Avatar>
      )}
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
        <Box
          style={{
            disBoxlay: 'flex',
            flexDirection: 'column',
            padding: 0,
            width: '100%',
            color: '#090909',
          }}
        >
          <Tooltip title={name} placement="top" arrow>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <p
                style={{
                  fontWeight: '500',
                  fontSize: '14px',
                  margin: 0,
                  display: '-webkit-box',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'normal',
                }}
              >
                {name}
              </p>
            </Box>
          </Tooltip>
          <p style={{ fontSize: '12px', lineHeight: '14px', color: '#9d9d9d' }}>
            {latestMessage ? (
              latestMessage.length > 12 ? (
                senderId === currentuser ? (
                  <>You: {latestMessage.slice(0, 12)}...</>
                ) : (
                  <>{latestMessage.slice(0, 12)}...</>
                )
              ) : senderId === currentuser ? (
                <>You: {latestMessage}</>
              ) : (
                <>{latestMessage}</>
              )
            ) : (
              <>&nbsp;</>
            )}
          </p>
        </Box>
        {unreadMessagesCount > 0 && (
          <span
            style={{
              backgroundColor: '#8ca4f8',
              color: 'white',
              width: '20px',
              height: '20px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              flexShrink: 0,
              fontSize: '10px',
              lineHeight: '12px',
            }}
          >
            {unreadMessagesCount}
          </span>
        )}
      </Box>
    </Box>
  );
}
