import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Courses/Home';
import { CourseRoutes } from '../Routes/CourseRouters';
import { TaskRoutes } from '../Routes/TaskRoute';
import OrganizationSetting from '../pages/Organization/OrganizationSetting';
import { ContestRoutes } from '../Routes/ContestRoutes';
import CheckOut from '../pages/Organization/PlansCheckOut';
import OrgPlans from '../pages/Organization/Plans';
import { AdminRoutes } from './AdminRoutes';
import { ProblemRoutes } from './ProblemRoutes';
import ChatRoutes from './ChatRoutes';
import MemberReport from '../pages/Organization/MemberReport';
import { DocManagementRoutes } from './DocManagementRoutes';
import { LazyComponent } from '../components/LazyLoader';
const PageNotFound = LazyComponent(() => import('../pages/PageNotFound/PageNotFound'));

export const OrganizationRoutes = ({ setorgImageStatus, orgImageStatus }) => {

  return (
    <Routes>
      <Route path="problem/*" element={<ProblemRoutes />} />
      <Route path="admin/*" element={<AdminRoutes />} />
      <Route path="/dashboard" element={<Home />} />
      <Route
        path="/settings"
        element={
          <OrganizationSetting
            orgImageStatus={orgImageStatus}
            setorgImageStatus={setorgImageStatus}
          />
        }
      />
      <Route path="course/*" element={<CourseRoutes />} />
      <Route path="doc/*" element={<DocManagementRoutes />} />
      <Route path="task/*" element={<TaskRoutes />} />
      <Route path="contest/*" element={<ContestRoutes />} />
      <Route path="/checkout/:planId" element={<CheckOut />} />
      <Route path="/plans" element={<OrgPlans />} />
      <Route path="/chat/*" element={<ChatRoutes />} />
      <Route path="/member/report" element={<MemberReport />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
