import { organizationAPI } from '../../../../api/requests/organization/organizationAPI';
import React, { useState, useEffect } from 'react';
import SentReceivedInvites from '../../OrganizationInvites/SentReceivedInvites/SentReceivedInvites';
import InviteWithCode from '../../OrganizationInvites/InviteWithCode/InviteWithCode';
import MembersListComponent from '../../../../components/LandingPage/OrganizationInvites/InvitesMemberList/InvitesMemberList';
import Skeletons from '../../../../components/Skeleton/Skeletons';
import { Box } from '@mui/material';

export default function OrganizationInvite({ userRole }) {
  const [isLoading, setLoading] = useState(false);
  const [invitesSent, setInvitesSent] = useState([]);
  const [invitesRecieved, setInvitesReceived] = useState([]);
  const [organizationMembers, setOrganizationMembers] = useState([]);
  const [code, setCode] = useState('');

  const fetchInvitesSentByOrganization = async () => {
    try {
      setLoading(true);

      const responseInviteSent = await organizationAPI.getInvitesSentByOrganization();
      setInvitesSent(responseInviteSent);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching suggested organizations:', error);
      setLoading(false);
    }
  };
  const fetchInvitesReceivedByOrganization = async () => {
    try {
      setLoading(true);
      const responseInvitesReceived = await organizationAPI.getRequestsRecievedByOrganization();
      setInvitesReceived(responseInvitesReceived);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching suggested organizations:', error);
      setLoading(false);
    }
  };
  const fetchOrganizationMembers = async () => {
    try {
      setLoading(true);
      const responseMemberList = await organizationAPI.getMembersList();
      setOrganizationMembers(responseMemberList.members);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching suggested organizations:', error);
      setLoading(false);
    }
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      await organizationAPI.getOrganizationById().then((res) => {
        setCode(res.organizationData.code);
        setLoading(false);
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  function fetchInvite() {
    fetchInvitesReceivedByOrganization();
    fetchInvitesSentByOrganization();
  }

  useEffect(() => {
    fetchOrganizationMembers();
    fetchData();
    fetchInvite();
  }, []);

  return isLoading ? (
    <Skeletons type="CircularLoader" />
  ) : (
    <Box sx={{ pt: '20px' }}>
      <InviteWithCode code={code} fetchInvites={fetchInvitesSentByOrganization} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: { lg: 'row', md: 'column', xs: 'column' },
          gap: 3,
        }}
      >
        <MembersListComponent
          organizationMembers={organizationMembers}
          userRole={userRole}
          fetchInvite={fetchInvitesSentByOrganization}
          DataFromApiInvites={invitesSent}
          fetchMembers={fetchOrganizationMembers}
          showRole={true}
          showDeleteButton={true}
        />
        <SentReceivedInvites
          DataFromApiRequests={invitesRecieved}
          fetchRequests={fetchInvitesReceivedByOrganization}
          fetchMembers={fetchOrganizationMembers}
        />
      </Box>
    </Box>
  );
}
