import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { CommonColors } from '../../utils/colors';
import ManualAvatar from '../Avatar/Avatar';
import MediaMessageBox from './MediaMessageBox';
import { ParseMessageWithEmojis, isOnlyEmojis } from '../../utils/emojiChecker';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import QuickreplyOutlinedIcon from '@mui/icons-material/QuickreplyOutlined';
import { FormattedDateTime } from '../../utils/dateFormater';

const ChatMessagePanel = ({
  message,
  isCurrentUser,
  prevMessage,
  style,
  onClick,
  onMouseEnter,
  onMouseLeave,
  currentUserInfo,
  openDetails,
  uploadLoader,
  handleDeleteMessage,
  handleEditMessage,
  handleReplyMessage,
  repliedMessages,
  setHoveredMessageId,
  hoveredMessageId,
}) => {
  const bgColor = CommonColors.primaryBgColor;
  const messageTime = new Date(message.created_at);
  const prevMessageTime = prevMessage ? new Date(prevMessage.created_at) : null;

  const isSameUserAndTime = () => {
    if (!prevMessage || prevMessage.sender_id !== message.sender_id) {
      return false;
    }
    return (
      prevMessageTime &&
      messageTime.getMinutes() === prevMessageTime.getMinutes() &&
      messageTime.getHours() === prevMessageTime.getHours()
    );
  };

  const shouldShowAvatar = !isSameUserAndTime() || !prevMessage;
  const isOnlyEmoji = isOnlyEmojis(message.message);

  if (message.type === 'INFO') {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          marginBottom: '8px',
          ...style,
        }}
      >
        <Box display={'flex'} flexDirection={'column'} width={'100%'}>
          {message.type === 'INFO' && (
            <Box
              sx={{
                display: 'flex',
                width: 'fit-content',
                maxWidth: '80%',
                height: 'fit-content',
                padding: '4px 10px',
                marginLeft: 'auto',
                marginRight: 'auto',
                wordBreak: 'break-word',
                borderRadius: '21px',
                border: '1px solid #D3D3D3',
                backgroundColor: '#E6E6E6',
                color: '#6E6E6E',
              }}
            >
              {message.message}
            </Box>
          )}
        </Box>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom:  '4px',
        ...style,
      }}
    >
      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
        {shouldShowAvatar && !isCurrentUser && (
          <Box
            sx={{
              marginLeft: '50px',
              alignSelf: 'flex-start',
              fontSize: '12px',
              fontWeight: 'bold',
            }}
            onMouseLeave={onMouseLeave}
            onMouseEnter={onMouseEnter}
          >
            {message.username}{' '}
          </Box>
        )}
        <Box display={'flex'}>
          <Box>
            {!isCurrentUser &&
              shouldShowAvatar &&
              (message.profilePhotoLink ? (
                <img
                  src={message.profilePhotoLink}
                  alt="profile"
                  style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                />
              ) : (
                <ManualAvatar chat={message} bgColor={bgColor} width="40px" height="40px" />
              ))}
          </Box>
          {message.replyMessageData ? (
            message.replyMessageData &&
            message.replyMessageData?.message?.id === message?.reply_id && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left',
                    border: '1px solid #D3D3D3',
                    width: 'fit-content',
                    marginLeft:
                      message.sender_id === currentUserInfo.id
                        ? 'auto'
                        : !shouldShowAvatar
                        ? '50px'
                        : '0px',
                    pointerEvents: openDetails || uploadLoader ? 'none' : 'auto',
                    opacity: openDetails || uploadLoader ? 0.5 : 1,
                    wordBreak: 'break-word',
                    borderRadius:
                      message.sender_id === currentUserInfo.id
                        ? '10px 0 10px 10px'
                        : '10px 0 10px 10px',
                    backgroundColor:
                      message.sender_id === currentUserInfo.id ? '#8CA4F8' : '#ECECEC',
                    color: message.sender_id === currentUserInfo.id ? 'white' : 'black',
                    alignItems: 'baseline',
                  }}
                  onMouseEnter={() => setHoveredMessageId(message?.id)}
                  onMouseLeave={() => setHoveredMessageId(null)}
                >
                  {hoveredMessageId === message.replyMessageData?.message?.id && (
                    <Box
                      onMouseEnter={() =>
                        setHoveredMessageId(message.replyMessageData?.message?.id)
                      }
                      onMouseLeave={() => setHoveredMessageId(null)}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '8px',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.10)',
                        p: 0.5,
                        width: 'fit-content',
                        left: message.sender_id === currentUserInfo.id ? 'auto' : '10px',
                        pointerEvents: 'auto',
                        position: 'absolute',
                        right: message.sender_id === currentUserInfo.id ? '10px' : 'auto',
                        top: '-32px',
                        zIndex: 5,
                      }}
                    >
                      <Tooltip title="Reply" arrow>
                        <IconButton onClick={() => handleReplyMessage(message)} size="small">
                          <QuickreplyOutlinedIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit" arrow>
                        {message.sender_id === currentUserInfo.id && (
                          <IconButton onClick={() => handleEditMessage(message)} size="small">
                            <EditIcon fontSize="small" />
                          </IconButton>
                        )}
                      </Tooltip>
                      <Tooltip title="Delete" arrow>
                        <IconButton onClick={() => handleDeleteMessage(message.id)} size="small">
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      borderRadius:
                        message.replyMessageData?.senderData?.id === currentUserInfo.id
                          ? '9px 0 0px 0px '
                          : '9px 0px 0px 0px ',
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        minWidth: '14px',
                        width: '4px',
                        borderTop: '3px solid #698aff',
                        borderLeft: '3px solid #698aff',
                        borderRadius:
                          message.replyMessageData?.senderData?.id === currentUserInfo.id
                            ? '9px 0 0px 0px '
                            : '9px 0 0px 0px',
                      }}
                    ></Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mr: '8px',
                        mb: '4px',
                        mt: '8px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'row',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <ManualAvatar
                          chat={message.replyMessageData?.senderData}
                          bgColor={bgColor}
                          width={'16px'}
                          height={'16px'}
                        />
                        <Box
                          sx={{
                            alignSelf: 'flex-start',
                            fontSize: '11px',
                            color: '#1f1f1f',
                          }}
                        >
                          {`${message.replyMessageData?.senderData?.firstName} ${message.replyMessageData?.senderData?.lastName}`}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          color:
                            message.replyMessageData?.senderData?.id === currentUserInfo.id
                              ? '#5f6368!important'
                              : 'black',
                          fontSize: '12px!important',
                          marginTop: '4px',
                        }}
                      >
                        <ParseMessageWithEmojis
                          message={message?.replyMessageData?.message?.message}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ p: '10px 10px 0 10px' }}>
                    {repliedMessages?.type === 'FILE' ? (
                      <MediaMessageBox message={message?.message} />
                    ) : (
                      <ParseMessageWithEmojis message={message?.message} />
                    )}
                  </Box>
                  <Box
                    style={{
                      fontWeight: 'normal',
                      fontSize: '10px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      width: '100%',
                      color: isCurrentUser ? '#E0E0E0' : '#8C8C8C',
                    }}
                  >
                    <FormattedDateTime messageTime={messageTime} />
                  </Box>
                </Box>
              </>
            )
          ) : (
            <Box
              sx={{
                padding: isOnlyEmoji ? '5px 5px 2px 5px' : '10px 7px 2px 15px',
                border: '1px solid #D3D3D3',
                borderRadius: isCurrentUser ? '10px 0 10px 10px ' : '0 10px 10px 10px ',
                width: 'fit-content',
                maxWidth: '50%',
                marginLeft: isCurrentUser ? 'auto' : !shouldShowAvatar ? '50px' : '0px',
                wordBreak: 'break-word',
                backgroundColor: isCurrentUser ? '#8CA4F8' : '#ECECEC',
                color: isCurrentUser ? 'white' : 'black',
                alignItems: 'baseline',
              }}
              onClick={onClick}
              onMouseLeave={onMouseLeave}
              onMouseEnter={onMouseEnter}
            >
              <Box
                sx={{
                  padding: '0 5px 5px 0',
                }}
              >
                {message.type === 'FILE' ? (
                  <MediaMessageBox message={message} />
                ) : (
                  message.type !== 'INFO' && <ParseMessageWithEmojis message={message.message} />
                )}
              </Box>
              <Box
                style={{
                  fontWeight: 'normal',
                  fontSize: '10px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                  color: isCurrentUser ? '#E0E0E0' : '#8C8C8C',
                }}
              >
                <FormattedDateTime messageTime={messageTime} />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ChatMessagePanel;
