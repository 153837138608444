import React, { useEffect, useState } from 'react';
import { Box, Typography, Stack, Button } from '@mui/material';
import Rupees_sign from '../../assets/LandingPage/Plans/Rupees_sign.svg';
import Rupees_sign_white from '../../assets/LandingPage/Plans/Rupees_sign_white.svg';
import Tick_icon from '../../assets/LandingPage/Plans/Tick_icon.svg';
import cross_icon from '../../assets/LandingPage/Plans/cross_icon.svg';
import { plansAPI } from '../../api/requests/plans';
import BigLoader from '../Skeleton/BigLoader';
import { useNavigate } from 'react-router-dom';
import { handleAlert } from '../../utils/handleAlert';

const PricingTab = () => {
  const isLoggedIn = localStorage.getItem('Token');
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    plansAPI
      .getPublicPlans()
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => {
        handleAlert('Something went wrong! please try again later', 'error');
        setLoading(false);
      });
  }, []);

  return loading ? (
    <BigLoader />
  ) : (
    <Box
      sx={{
        backgroundColor: 'rgba(246, 248, 255, 1)',
        padding: '50px 0',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: { md: 0, xs: 3 },
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Montserrat',
            fontSize: { md: '40px', xs: '25px' },
            fontWeight: '700',
            lineHeight: '51px',
            color: 'rgba(2, 17, 72, 1)',
          }}
        >
          Find the Right Plan and
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Montserrat',
            fontSize: { md: '40px', xs: '25px' },
            fontWeight: '700',
            lineHeight: '51px',
            color: 'rgba(105, 138, 255, 1)',
            pb: 1,
          }}
        >
          Get Started Today
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Montserrat',
            fontSize: { md: '20px', xs: '17px' },
            fontWeight: '400',
            color: 'rgba(25, 29, 35, 1)',
            pb: 2,
          }}
        >
          Choose a plan that’s right for you
        </Typography>
      </Box>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: { md: 'row', xs: 'column' },
          gap: 3,
          justifyContent: 'center',
          margin: { md: '25px 0 0 0', xs: '25px 25px 0 25px' },
          alignItems: 'center',
        }}
      >
        {data?.plans?.map((plan, key) => {
          const isMiddleCard = key === 1;
          return (
            <Box
              key={key}
              sx={{
                borderRadius: '12px',
                padding: '32px 24px',
                backgroundColor: isMiddleCard ? 'rgba(105, 138, 255, 1)' : 'white',
                boxShadow: isMiddleCard ? '0px 10px 25px 0px rgba(255, 234, 219, 1)' : 'none',
                maxWidth: '330px',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '22px',
                  fontWeight: '600',
                  color: isMiddleCard ? 'white' : 'blrgba(70, 70, 70, 1)ack',
                  pb: 1,
                }}
              >
                {plan.name}
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '16px',
                  fontWeight: '400',
                  color: isMiddleCard ? 'white' : 'rgba(100, 100, 100, 1)',
                  pb: 1,
                }}
              >
                {plan.description}
              </Typography>
              <Box
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, pb: 1 }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '48px',
                    fontWeight: '600',
                    color: isMiddleCard ? 'white' : 'rgba(70, 70, 70, 1)',
                  }}
                >
                  <img
                    src={isMiddleCard ? Rupees_sign_white : Rupees_sign}
                    alt=""
                    style={{
                      marginRight: '5px',
                    }}
                  />
                  {plan.price}
                </Typography>
                {plan.price !== 0 && (
                  <Typography
                    sx={{
                      fontFamily: 'Montserrat',
                      fontSize: '16px',
                      fontWeight: '400',
                      color: isMiddleCard ? 'white' : 'rgba(100, 100, 100, 1)',
                    }}
                  >
                    /Month
                  </Typography>
                )}
              </Box>
              <Button
                onClick={() => {
                  if (isLoggedIn) navigate('/organization');
                  else navigate('/sign-in');
                }}
                variant="outlined"
                fullWidth
                sx={{
                  backgroundColor: 'white',
                  mb: 3,
                  '&:hover': {
                    backgroundColor: isMiddleCard ? '#F4F4F4' : 'rgba(105, 138, 255, 0.1)',
                    borderColor: isMiddleCard ? 'white' : 'rgba(105, 138, 255, 1)',
                  },
                }}
              >
                get started now
              </Button>

              <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                {JSON.parse(plan.data).map((feature, key) => {
                  const shouldUseTick =
                    plan.name === 'Basic Plan' &&
                    (feature === 'No limit on no of tasks' ||
                      feature === '15 GB of cumulative space available to create courses');

                  return (
                    <li
                      key={key}
                      style={{
                        padding: '10px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={
                          plan.name === 'Basic Plan'
                            ? shouldUseTick
                              ? Tick_icon
                              : cross_icon
                            : Tick_icon
                        }
                        alt=""
                        style={{
                          flexShrink: 0,
                          width: '20px',
                          height: '20px',
                        }}
                      />
                      <span
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '16px',
                          fontWeight: '400',
                          color: isMiddleCard ? 'white' : 'rgba(100, 100, 100, 1)',
                          paddingLeft: '10px',
                          display: 'inline-block',
                        }}
                      >
                        {feature}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default PricingTab;
