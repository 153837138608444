import React from 'react';
import { Avatar, Box, InputAdornment, TextField, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function UserSearchList({
  input,
  setInput,
  searchedUsers,
  handleChatClick,
  handleAddition,
  handleResetSearchUser,
  width,
  placeholder,
  style,
  height,
  marginTop,
}) {
  return (
    <Box
      marginTop={marginTop || '0'}
      width={width}
      display={'flex'}
      alignItems={'center'}
      marginX={'0'}
      justifyContent={'center'}
      position={'relative'}
      gap={'10px'}
      sx={{
        p: '20px 0px 20px 28px',
        backgroundColor: '#fff',
        width: '100%',
        borderRadius: '9px 9px 0 0',
        borderBottom: '1px solid #eeeff1',
      }}
    >
      <TextField
        fullWidth
        variant="outlined"
        placeholder={placeholder || 'Search...'}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        sx={{
          '& .MuiOutlinedInput-root': {
            height: { height },
            fontSize: '15px',
            backgroundColor: '#f4f6ff!important',
            borderColor: '#eeeff1',
          },
          '& .MuiSvgIcon-root': {
            fontSize: '20px',
          },
          width: '100%',
        }}
        {...style}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: input ? (
            <IconButton aria-label="remove" size="small" onClick={() => handleResetSearchUser()}>
              <CloseRoundedIcon />
            </IconButton>
          ) : null,
        }}
      />
      {searchedUsers.length > 0 && (
        <Box
          width={'100%'}
          // border={'1px solid #D3D3D3 '}
          maxHeight={'120px'}
          height={'max-content'}
          marginTop={'1px'}
          borderBottom={'1px solid #eeeff1'}
          top={'100%'}
          sx={{
            overflowY: 'auto',
            position: 'absolute',
            zIndex: 10,
            px: '20px',
            backgroundColor: '#EEEEEE',
          }}
        >
          {searchedUsers.map((user, index) => (
            <Box
              display={'flex'}
              flexDirection={'column'}
              my={'4px'}
              alignItems={'start'}
              key={user.id}
              padding={'4px'}
              sx={{
                cursor: 'pointer',
              }}
            >
              <Box
                display={'flex'}
                gap={1}
                alignItems={'center'}
                fontSize={'14px'}
                onClick={() => {
                  handleChatClick && handleChatClick(user);
                  handleAddition(user);
                }}
              >
                <Avatar
                  src={user.profilePhotoLink}
                  style={{
                    backgroundColor: user.profilePhotoLink ? 'transparent' : '#354150',
                    color: user.profilePhotoLink ? 'black' : 'white',
                    width: '30px',
                    height: '30px',
                    fontSize: '19px',
                    borderRadius: '4px',
                  }}
                >
                  {user.firstName.charAt(0).toUpperCase()}
                </Avatar>
                <Box>
                  <p>{`${user.firstName} ${user.lastName}`.toUpperCase()}</p>
                  <p style={{ fontSize: '10px', lineHeight: '12px' }}>{`(${user.username})`}</p>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
