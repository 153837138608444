import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import DefaultCourseProfile from '../../assets/CourseImages/DefaultCourseProfile.webp';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { handleAlert } from '../../utils/handleAlert';
import { courseAPI } from '../../api/requests/courses/courseAPI';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
export default function CourseCard({ Data, setSelectedCourseId, selectedCourseId, fetchData }) {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteCourse = async (id) => {
    await courseAPI
      .deleteCourse(id)
      .then((res) => {
        handleAlert(res?.data?.message, 'success');
        setOpenDeleteDialog(false);
        fetchData();
      })
      .catch((err) => {
        handleAlert(err?.message, 'error');
      });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '400px',
        width: { xs: '100%', lg: '35%' },
        overflow: 'scroll',
        gap: '10px',
      }}
    >
      {Data?.map((course, index) => (
        <Box
          key={course?.id}
          sx={{
            display: 'flex',
            gap: '15px',
            borderRadius: '10px',
            background:
              selectedCourseId === course?.id || (index === 0 && !selectedCourseId)
                ? 'rgba(241, 244, 255, 1)'
                : '#FFF',
            border:
              selectedCourseId === course?.id || (index === 0 && !selectedCourseId)
                ? '1px solid rgba(105, 138, 255, 1)'
                : '1px solid rgba(225, 225, 225, 1)',
            p: '12px',
            cursor: 'pointer',
            alignItems: 'center',
          }}
          onClick={() => {
            setSelectedCourseId(course?.id);
          }}
        >
          <img
            style={{
              width: '80px',
              height: '60px',
            }}
            src={course?.profilephoto || DefaultCourseProfile}
            alt=""
          />
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'baseline' }}>
              <Typography
                sx={{
                  color: 'rgba(2, 17, 72, 0.9)',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                {course?.courseName}
              </Typography>
            </Box>
            <Box sx={{ width: '100%', display: 'flex', gap: 2, alignItems: 'center' }}>
              <Box sx={{ width: { md: '50%', xs: '70%' } }}>
                <LinearProgress
                  variant="determinate"
                  value={course?.percentage}
                  sx={{ height: '6px', borderRadius: '80px' }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Typography
                  sx={{
                    color: 'rgba(62, 67, 75, 1)',
                    fontFamily: 'Poppins',
                    fontSize: '12px',
                    fontWeight: '500',
                  }}
                >
                  {course?.percentage} %
                </Typography>
                <Typography
                  sx={{
                    display: { md: 'flex', xs: 'none' },
                    color: 'rgba(62, 67, 75, 1)',
                    fontFamily: 'Poppins',
                    fontSize: '12px',
                    fontWeight: '500',
                  }}
                >
                  STORAGE USED
                </Typography>
              </Box>
            </Box>
          </Box>
          <Tooltip
            title="Delete Course"
            onClick={() => {
              handleDeleteDialog(course?.id);
            }}
          >
            <IconButton>
              <DeleteOutlineIcon
                sx={{
                  color: 'rgba(135, 135, 135, 1)',
                }}
              />
            </IconButton>
          </Tooltip>
          <Dialog
            open={openDeleteDialog}
            onClose={handleCloseDeleteDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'Do you really want to delete?'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                this course will delete permanently
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleDeleteCourse(course?.id)}>Yes</Button>
              <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </Box>
      ))}
    </Box>
  );
}
