import React, { useEffect, useState } from 'react';
import { Stack, Typography, Button, Box, Tooltip } from '@mui/material';
import { organizationAPI } from '../../api/requests/organization/organizationAPI';
import { courseAPI } from '../../api/requests/courses/courseAPI';
import Product from '../ProductCarousel/Product';
import SettingsContestComponent from '../../pages/Contest/SettingsContestComponent';
import Skeletons from '../Skeleton/Skeletons';
import Avatar from '@mui/material/Avatar';
import EditOrganizationModal from './EditOrganizationModal';
import { useNavigate } from 'react-router-dom';
import DeleteOrganizationModal from './DeleteOrganizationModal';

export default function BasicInfo({ userRole }) {
  const [isLoading, setLoading] = useState(true);
  const [orgData, setOrgData] = useState(null);
  const [courses, setCourses] = useState();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const getAllData = async () => {
      setLoading(true);
      await getCourses();
      setLoading(false);
    };
    getAllData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await organizationAPI.getOrganizationById();
      setOrgData(res.organizationData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getCourses = async () => {
    try {
      setLoading(true);
      const data = await courseAPI.getCoursesByPage(1);
      setCourses(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const handleEditOrgClick = () => {
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleDeleteOrgClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleOrgDelete = async () => {
    try {
      await organizationAPI.removeOrganization(orgData.id);
      navigate('/organization');
    } catch (error) {
      console.error('Error deleting organization:', error);
    }
  };
  return isLoading ? (
    <Skeletons type="CircularLoader" />
  ) : (
    <Stack sx={{ gap: 3, flexDirection: 'column', pt: '20px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { md: 'row', xs: 'column' },
          alignItems: { md: 'center', xs: 'left' },
          justifyContent: 'space-between',
          gap: 2,
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            flexGrow: 1,
            minWidth: 0,
          }}
        >
          {orgData && orgData.logoLink ? (
            <Avatar
              alt={orgData && orgData.name}
              src={orgData && orgData.logoLink}
              sx={{
                height: '80px',
                width: '80px',
              }}
            />
          ) : (
            <Avatar
              sx={{
                height: '80px',
                width: '80px',
                borderRadius: '10px',
                bgcolor: 'rgba(241, 244, 255, 1)',
                color: 'rgba(105, 138, 255, 1)',
              }}
            >
              {orgData && orgData.name ? orgData.name.charAt(0).toUpperCase() : ''}
            </Avatar>
          )}
          <Box
            sx={{
              minWidth: 0,
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
            }}
          >
            <Tooltip title={orgData && orgData.name}>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '20px',
                fontWeight: '500',
                textTransform: 'uppercase',
                // display: '-webkit-box',
                // WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'normal', // Change to nowrap for single-line truncation
              }}
            >
              {orgData && orgData.name}
            </Typography>
            </Tooltip>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontWeight: '400',
                color: 'rgba(120, 120, 120, 1)',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {orgData && orgData.description}
            </Typography>
          </Box>
        </Box>

        {userRole === 'CREATOR' && (
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              flexShrink: 0,
            }}
          >
            <Button
              variant="outlined"
              color="error"
              fullWidth
              sx={{
                minWidth: '120px',
                height: '36px',
                padding: '6px 16px',
              }}
              onClick={handleDeleteOrgClick}
            >
              Delete Org
            </Button>
            <Button
              variant="contained"
              fullWidth
              sx={{
                minWidth: '120px',
                height: '36px',
                padding: '6px 16px',
              }}
              onClick={handleEditOrgClick}
            >
              Edit Org
            </Button>
          </Box>
        )}
      </Box>

      <Product
        title={'Courses in your Organization '}
        dataRender={courses}
        loading={isLoading}
        isEditable={true}
        isUserProfileInProduct={true}
        getCourses={getCourses}
        courses={courses}
      />
      <SettingsContestComponent OrganizationName={orgData && orgData.name} />
      <EditOrganizationModal
        open={isEditModalOpen}
        onClose={handleCloseEditModal}
        organizationName={orgData && orgData?.name}
        organizationDescription={orgData?.description}
        organizationPhotoLink={orgData?.logoLink}
        organizationId={orgData?.id}
        fetchData={fetchData}
      />
      <DeleteOrganizationModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        organizationName={orgData?.name}
        onDelete={handleOrgDelete}
      />
    </Stack>
  );
}
