import io from 'socket.io-client';
import axios from 'axios';
import GetValidatedTokenData from '../utils/helper';

class SocketClient {
  static instance = null;
  socket = null;

  constructor() {
    if (SocketClient.instance) {
      return SocketClient.instance;
    }
    SocketClient.instance = this;
  }

  async initSocket() {
    if (!this.socket) {
      const ipResponse = await axios.get('https://api.ipify.org/?format=json');
      const currentIp = ipResponse.data.ip;

      const currentUserInfo = GetValidatedTokenData();

      this.socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
        query: {
          userId: currentUserInfo.id,
          clientIp: currentIp,
        },
        extraHeaders: {
          'Access-Control-Allow-Origin': '*',
          'Cross-Origin-Opener-Policy': 'same-origin-allow-popups',
        },
      });
    }
    return this.socket;
  }

  async getSocket() {
    if(!this.socket){
      await this.initSocket()
    }
    return this.socket;
  }
}

export default new SocketClient();
