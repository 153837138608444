import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { handleAlert } from '../../utils/handleAlert';
import { ChatApi } from '../../api/requests/chat/ChatApi';
import GetValidatedTokenData from '../../utils/helper';

export default function ChatReactionBox({
  isCurrentUser,
  reactionData,
  setReactionsData,
  reactionsData,
}) {
  const currentUserInfo = GetValidatedTokenData();
  const handleRemoveReaction = async (msgId, emojiContent) => {
    try {
      const response = await ChatApi.removeEmojiReaction(msgId);
      const updatedReactionsData = reactionsData
        .map((reaction) => {
          if (reaction.msgId === msgId) {
            return {
              ...reaction,
              emojiData: reaction.emojiData
                .map((emoji) => {
                  if (emoji.content === emojiContent) {
                    return {
                      ...emoji,
                      users: emoji.users.filter((user) => user.id !== currentUserInfo.id),
                    };
                  }
                  return emoji;
                })
                .filter((emoji) => emoji.users.length > 0),
            };
          }
          return reaction;
        })
        .filter((reaction) => reaction.emojiData.length > 0);
      setReactionsData(updatedReactionsData);
    } catch (error) {
      handleAlert(error.message, 'warning');
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent={'flex-end'}
      width={'fit-content'}
      marginLeft={isCurrentUser ? 'auto' : '50px'}
    >
      {reactionData?.emojiData?.map((item) => {
        const titleText =
          item?.users
            .map((user) =>
              currentUserInfo.id === user.id ? 'You' : `${user.firstName} ${user.lastName}`
            )
            .join(', ') + ` reacted with ${item.content} to the message`;

        return (
          <Typography
            key={item.content}
            sx={{
              padding: '3px',
              border: '1px solid #D3D3D3',
              borderRadius: '5px',
              width: 'fit-content',
              backgroundColor: '#ECECEC',
              color: 'black',
              alignItems: 'baseline',
              marginBottom: '5px',
              marginRight: '1px',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: isCurrentUser ? '#8CA4F8' : '#ECECEC',
              },
            }}
            onClick={() => {
              if (item.users.some((user) => user.id === currentUserInfo.id)) {
                handleRemoveReaction(reactionData.msgId, item.content);
              }
            }}
          >
            <Tooltip title={titleText}>
              {item.content} <span style={{ fontSize: '14px' }}>{item.users.length}</span>
            </Tooltip>
          </Typography>
        );
      })}
    </Box>
  );
}
