import React from 'react';
import { ASSET_CONFIGS } from '../../assets/assetConfigs';
import LOGO from '../../assets/LandingPage/Footer/opti_logo.svg';
import { useLocation , useNavigate } from 'react-router-dom';
import './Footer.css';

export default function Footer({ scrollToSection, featuresRef }) {
  const navigate = useNavigate();
  const location = useLocation();

  const imagesObj = JSON.parse(localStorage.getItem('imagesObj'));
  let contact_logo_footer_url = imagesObj && imagesObj[ASSET_CONFIGS.CONTACT_LOGO_FOOTER];

  const handleNavClick = (link) => {
    if (link === '/') {
      navigate('/');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (['products'].includes(link)) {
      if (location.pathname !== '/') {
        navigate('/', { state: { scrollTo: link } });
      } else {
        scrollToSection(featuresRef);
      }
    } else if (link === '/contact-support' || link === '/careers') {
      navigate(link);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
    } else {
      navigate(link);
    }
  };

  const info = [
    {
      id: 1,
      title: 'Home',
      link: '/',
    },

    {
      id: 2,
      title: 'Features',
      link: 'products',
    },
    {
      id: 3,
      title: 'Contact us',
      link: '/contact-support',
    },
  ];
  const links = [
    {
      id: 1,
      title: 'Support',
      link: '/contact-support',
    },
    {
      id: 2,
      title: 'Terms & Conditions',
      link: '/terms&conditions',
    },
    {
      id: 3,
      title: 'Privacy Policy',
      link: '/privacy-policy',
    },
    {
      id: 4,
      title: 'Refund  policy',
      link: '/refund-cancellation',
    },
  ];
  const icons = [
    {
      id: 1,
      svg: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="svg-path"
            d="M16 8C17.5913 8 19.1174 8.63214 20.2426 9.75736C21.3679 10.8826 22 12.4087 22 14V21H18V14C18 13.4696 17.7893 12.9609 17.4142 12.5858C17.0391 12.2107 16.5304 12 16 12C15.4696 12 14.9609 12.2107 14.5858 12.5858C14.2107 12.9609 14 13.4696 14 14V21H10V14C10 12.4087 10.6321 10.8826 11.7574 9.75736C12.8826 8.63214 14.4087 8 16 8V8Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            className="svg-path"
            d="M6 9H2V21H6V9Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            className="svg-path"
            d="M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      link: 'https://www.linkedin.com/company/simppwey-private-limited/',
    },
    {
      id: 2,
      svg: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="svg-path"
            d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            className="svg-path"
            d="M22 6L12 13L2 6"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      link: 'mailto:support@optigrit.com',
    },
  ];
  return (
    <div className="footerCompleteCon">
      <div className="footerUpCon">
        <div>
          <a href="/">
            <img
              src={LOGO}
              alt=""
              style={{
                marginBottom: '10px',
              }}
            />
          </a>
          <p className="footerUp1_1_text">
            Optigrit Technologies Pvt Ltd. <br />
            {/* (An ISO 9001:2008 Certified Company) */}
          </p>
        </div>
        <div className="footer_up_2">
          <div className="footerUp1_1">
            <h5 className="h5_footer_heading">Contact Details</h5>
            <p className="footerUp1_1_text">
              {/* 201, 45 Hare Krishna Vihar, Behind Advance Academy School, Nipania, Indore, 452010 MP */}
            </p>
            <p className="footerUp1_1_text" style={{ marginTop: '10px' }}>
              support@optigrit.com <br />
              Call(Sales): +91-9878324029
            </p>
          </div>
          <div className="footer_up_right">
            <div className="footerUp2">
              <h5 className="h5_footer_heading">Quick</h5>
              <ul>
                {info.map(({ id, title, link }) => (
                  <a
                    onClick={() => handleNavClick(link)}
                    key={id}
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                  >
                    <li className="footerLinksLi">{title}</li>
                  </a>
                ))}
              </ul>
            </div>
            <div className="footerUp3">
              <h5 className="h5_footer_heading">About</h5>
              <ul>
                {links.map(({ id, title, link }) => (
                  <a href={link} key={id} style={{ textDecoration: 'none' }}>
                    <li className="footerLinksLi" key={title}>
                      {title}
                    </li>
                  </a>
                ))}
              </ul>
            </div>
            <div className="footerUp4">
              <h5 className="h5_footer_heading">Connect with us</h5>

              <div className="footerDown1">
                {icons.map(({ id, svg, link }) => (
                  <a
                    href={link}
                    key={id}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footerDown1_icons"
                  >
                    {svg}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footerDownCon">
        <div className="footerDown2">
          <p>
            © Copyright 2022 Optigrit, Inc. All rights reserved. Various trademarks held by their
            respective owners.
          </p>
        </div>
      </div>
    </div>
  );
}
